import jwtDecode from "jwt-decode";
import publicAxios from "../publicAxios";
import { ISession } from "../../Context/Session/SessionContext";

export interface IJWT {
    sub: string;
    email_verified: boolean;
    iss: string;
    "cognito:username": string;
    origin_jti: string;
    aud: string;
    event_id: string;
    token_use: string;
    auth_time: number;
    name: string;
    exp: number;
    iat: number;
    jti: string;
    email: string;
}

/**
 * Obtiene nuevos tokens usando el refresh token
 * @function
 * @async
 * @param {Object} OldTokens
 * @return {Object} Newtokens
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function getNewAccesToken(oldTokens: ISession) {
    const user: IJWT = jwtDecode(oldTokens.access_token);

    const result = await publicAxios
        .post<ISession>("auth/refresh", {
            email: user.email,
            refreshToken: oldTokens.refresh_token,
        })
        .then((response) => {
            return { error: false, res: response.data };
        })
        .catch((error) => {
            return { error: true, res: error };
        });
    return result;
}

export default getNewAccesToken;
