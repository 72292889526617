type TTokens = {
    access_token: string;
    refresh_token: string;
};

/**
 * Obtiene los tokens en localStorage
 * o session storage
 * @returns {TTokens | null}
 */
function getStorageTokens(): TTokens | null {
    const localStorageTokens = localStorage.getItem("tokens");
    const sessionStorageTokens = sessionStorage.getItem("tokens");

    if (localStorageTokens) {
        return JSON.parse(localStorageTokens) as TTokens;
    }

    if (sessionStorageTokens)
        return JSON.parse(sessionStorageTokens) as TTokens;
    console.log("qwdqwdqd qwd sessionStorage");
    return null;
}

export default getStorageTokens;
