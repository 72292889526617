/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import SessionProvider from "./Context/Session/SessionProvider";
import { store } from "./redux/store";
import App from "./App";

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
    <Provider store={store}>
        <PersistProvider>
            <ThemeProvider hideSettingsCard>
                <SessionProvider>
                    <App />
                </SessionProvider>
            </ThemeProvider>
        </PersistProvider>
    </Provider>
);
