import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Preloader from "./components/preloader";

const Signin = lazy(() => import("./pages/databoards/Signin"));
const Dashboards = lazy(() => import("./pages/databoards/Dashboards"));
const Dashboard = lazy(() => import("./pages/databoards/Dashboard"));
const NewDashboard = lazy(() => import("./pages/databoards/NewDashboard"));
const Connections = lazy(() => import("./pages/databoards/Connections"));
const NewConnection = lazy(() => import("./pages/databoards/NewConnection"));
const ConnectionProfile = lazy(
    () => import("./pages/databoards/ConnectionProfile")
);
const NewMetric = lazy(() => import("./pages/databoards/NewMetric"));
const Metrics = lazy(() => import("./pages/databoards/Metrics"));
const Signup = lazy(() => import("./pages/databoards/Signup"));
const ConfirmCode = lazy(() => import("./pages/databoards/confirmCode"));
const Forgot = lazy(() => import("./pages/databoards/Forgot"));
const ConfirmPassword = lazy(
    () => import("./pages/databoards/ConfirmPassword")
);
const Users = lazy(() => import("./pages/databoards/Users"));

// eslint-disable-next-line @typescript-eslint/no-unsafe-return
const PrivateRoutes = lazy(() => import("./routes/PrivateRoutes"));
const PublicRoutes = lazy(() => import("./routes/PublicRoutes"));

const App: React.FC = () => {
    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        <Route element={<PrivateRoutes />}>
                            <Route
                                path="/connections"
                                element={<Connections />}
                            />
                            <Route
                                path="/new/connection"
                                element={<NewConnection />}
                            />
                            <Route
                                path="/dashboards"
                                element={<Dashboards />}
                            />
                            <Route
                                path="/dashboard/:id"
                                element={<Dashboard />}
                            />
                            <Route
                                path="/new/dashboard"
                                element={<NewDashboard />}
                            />

                            <Route
                                path="/connection/:id"
                                element={<ConnectionProfile />}
                            />

                            <Route
                                path="/dashboard/:dashboard_id/metric"
                                element={<NewMetric />}
                            />
                            <Route
                                path="/dashboard/:dashboard_id/metric/:id"
                                element={<NewMetric />}
                            />

                            <Route path="/metrics" element={<Metrics />} />

                            <Route path="/new/metric" element={<NewMetric />} />
                            <Route path="/metric/:id" element={<NewMetric />} />
                            <Route path="/users" element={<Users />} />
                        </Route>
                        <Route element={<PublicRoutes />}>
                            <Route path="/signin" element={<Signin />} />
                            <Route path="/" element={<Signin />} />

                            <Route path="/signup" element={<Signup />} />
                            <Route
                                path="/confirm/code"
                                element={<ConfirmCode />}
                            />
                            <Route path="/forgot" element={<Forgot />} />
                            <Route
                                path="/confirm/Password"
                                element={<ConfirmPassword />}
                            />
                        </Route>
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
